<template>
  <Navbar />

  <div class="container">
    <router-view />
  </div>
  <Footer />
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: inherit;
}
#app {
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 18px;
  overflow-x: hidden;
}
.container {
  margin-top: 70px;
}
nav {
  a {
    font-weight: bold;

    &.router-link-exact-active {
      color: #2ea9e0;
    }
  }
}
</style>
