<template>
  <nav class="navbar">
    <div class="wrapper">
      <img src="@/assets/winmed.svg" alt="" class="logo" />
      <div class="lang">
        <a @click="setLang('ua')" class="ua">
          <img src="@/assets/ukraine.png" alt="" />
        </a>
        /
        <a @click="setLang('en')" class="en">
          <img src="@/assets/united-states.png" alt="" />
        </a>
      </div>
      <div class="nav-menu" v-show="!mobile">
        <router-link class="nav-item" to="/">{{ $t("navMain") }}</router-link>
        <router-link class="nav-item" to="/about">
          {{ $t("navAbout") }}
        </router-link>
        <router-link class="nav-item" to="/contact">
          {{ $t("navContact") }}
        </router-link>
      </div>
      <div class="icon" v-show="mobile">
        <img
          src="@/assets/menu_icon.svg"
          alt=""
          :class="{ 'icon-active': mobileNav }"
          @click="toggleMobileNav"
        />
      </div>
      <transition name="mobile-nav">
        <div class="drop-down-menu" v-show="mobileNav">
          <router-link class="nav-item" to="/">{{ $t("navMain") }}</router-link>
          <router-link class="nav-item" to="/about">
            {{ $t("navAbout") }}
          </router-link>
          <router-link class="nav-item" to="/contact">
            {{ $t("navContact") }}
          </router-link>
        </div>
      </transition>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      mobile: true,
      mobileNav: null,
      windowWidth: null,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  mounted() {
    if (localStorage.locale) this.$i18n.locale = localStorage.locale;
  },
  watch: {
    $route() {
      this.mobileNav = false;
    },
    locale(newLocale) {
      localStorage.locale = newLocale;
    },
  },
  methods: {
    setLang(locale) {
      this.$i18n.locale = locale;
      localStorage.locale = locale;
    },
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 760) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  color: #000;
  background-color: #fff;
  box-shadow: 0 0 10px 1px gray;
  z-index: 100;
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: 1600px;
    width: 100%;
    margin-inline: auto;
    .lang {
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        cursor: pointer;
        margin: 0.5rem;
        img {
          width: 20px;
        }
      }
    }
    .logo {
      width: 200px;
    }
    .nav-menu {
      margin-right: 10px;
      .nav-item {
        margin-left: 1rem;
      }
      .form-control {
        cursor: pointer;
        margin-left: 1rem;
        height: 30px;
        width: 50px;
        border-radius: 8px;
        color: #2ea9e0;
        .option {
          cursor: pointer;
        }
      }
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        cursor: pointer;
        width: 30px;
      }
      .form-control {
        cursor: pointer;
        margin-inline: 1rem;
        height: 25px;
        width: 50px;
        border-radius: 8px;
        color: #2ea9e0;
        .option {
          cursor: pointer;
        }
      }
    }
    .icon-active {
      transform: rotate(180deg);
    }
    .drop-down-menu {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 250px;
      height: 100vh;
      background-color: #fff;
      a {
        margin-left: 2em;
        margin-bottom: 2em;
      }
    }

    .mobile-nav-enter-active,
    .mobile-nav-leave-active {
      transition: 1s ease all;
    }

    .mobile-nav-enter-from,
    .mobile-nav-leave-to {
      transform: translateX(-250px);
    }
    .mobile-nav-enter-to {
      transform: translateX(0);
    }
  }
}
@media only screen and (max-width: 765px) {
  .navbar {
    .wrapper {
      .logo {
        width: 150px;
      }
      .nav-menu {
        font-size: 16px;
      }
    }
  }
}
</style>
