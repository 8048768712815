<template>
  <footer class="footer">
    <div class="wrapper">
      <img src="@/assets/winmed.svg" alt="" />
      <div class="additional">WinMed</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComp",
};
</script>

<style lang="scss" scoped>
.footer {
  position: static;
  bottom: 0;
  left: 0;
  height: 200px;
  padding-inline: 1rem;
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1600px;
    width: 100%;
    height: 100%;
    margin-inline: auto;
    img {
      width: 200px;
    }
  }
}
</style>
