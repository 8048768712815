import { createI18n } from "vue-i18n";
import en from "../langs/en.json";
import ua from "@/langs/ua.json";

const i18n = createI18n({
  locale: "ua",
  fallbackLocale: "en",
  messages: {
    ua,
    en,
  },
});

export default i18n;
